.registerMainDiv{
    display:flex !important;
    flex-direction:column !important;
    justify-content:center !important;
    overflow: scroll;
    overflow: auto;
    flex-grow:1;
    
   }

.registerFormFlex{
    display: flex !important;
    flex-direction: column !important;
    margin-left: 45px;
    height: 100%;
    
}

.registerSideLogo{
    width: 100%;
    height: 100%;
    min-height: 650px;
    overflow: hidden;
}

.dropZone {
    height:200px;
    width:90%;
    border:2px dashed pink;
    border-radius: 10px;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-bottom:30px;
}

::-webkit-scrollbar {
    width: 8px;
    border-radius: 25px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 25px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 25px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 25px;
}

