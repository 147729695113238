* {
    box-sizing: border-box;
}

.bg-shadow::after {
    content: " ";
    height: 50%;
    width: 100%;
    position: absolute;
    opacity: 0.8;
    bottom: 0;
    background-image: linear-gradient(#79559408 ,#9b4acd87);
    border-radius: 10px;
}
.bg-shadow{
    position: relative;
}
.bg-shadow .MuiTypography-root{
    z-index: 1;
}

.section4image{
    /* width: 650px !important;
    height: 350px; */
    /* border-bottom: 20px solid #d6b236; */
    width: 100%;
}

.div1{
    width: 100%;
    /* width: 650px !important; */
}

.div2{
    width: 100%;
    /* width: 650px !important; */
}

/* @media only screen and (max-width: 1350px) {
    .section4image{
        width: 450px;
        height:250px;
    }
    .div1{
        width: 450px;
    }
    .div2{
        width: 450px;
    }
}

@media only screen and (max-width: 600px) {
    .section4image{
        width: 350px;
        height:300px;
    }
    .div1{
        width: 350px;
    }
    .div2{
        width: 350px;
    }
} */

